import React, { useEffect, useState } from 'react';
import ReactMarkdown from 'react-markdown';

const TermsConditions = ({ file, logo }) => {
  const [content, setContent] = useState('');

  useEffect(() => {
    // Fetch the markdown content
    fetch(file)
      .then((response) => response.text())
      .then((text) => setContent(text));
  }, [file]);

  return (
    <div
      style={{
        padding: '20px',
        paddingBottom:'200px',

        height: '100vh', // Full viewport height
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start', // Align content to the start
        overflowY: 'auto', // Enable scrolling
        backgroundColor: 'rgba(255, 255, 255, 0.3)', // Glassy background effect
        backdropFilter: 'blur(10px)', // Blur effect for the glassy effect
        WebkitBackdropFilter: 'blur(10px)', // For Safari
        borderRadius: '8px',
        boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)', // Optional shadow for depth
        margin: '0 10%', // Add margins on the sides for a centered look
      }}
    >
      <img
        src={logo}
        alt="Logo"
        style={{
          width: '100px',
          marginBottom: '20px',
          alignSelf: 'flex-start', // Align logo to the top start
        }}
      />
      <ReactMarkdown>{content}</ReactMarkdown>
    </div>
  );
};

export default TermsConditions;
