import { AppBar, Box, IconButton, Toolbar } from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom'; // Import Link from react-router-dom
import FacebookIcon from '../assets/res/facebook2.svg'; // Path to your custom Facebook icon
import InstagramIcon from '../assets/res/insta.svg'; // Path to your custom Instagram icon
import AppIcon from '../assets/res/Logo_Light.svg'; // Path to your app icon
import YouTubeIcon from '../assets/res/youtube.svg'; // Path to your custom YouTube icon

const Header = () => {
  return (
    <AppBar position="static" color="transparent" elevation={0}>
      <Toolbar sx={{ justifyContent: 'space-between' }}>
        {/* Left side: App Icon and Link to Home */}
        <Box display="flex" alignItems="center">
          <Link to="/"> {/* Link to the home route */}
            <Box component="img" src={AppIcon} alt="App Logo" width={40} height={40} mr={1} sx={{ cursor: 'pointer' }} />
          </Link>
        </Box>

        {/* Right side: Social Media Icons */}
        <Box>
          {/* Facebook */}
          <IconButton color="inherit">
            <a href="https://www.facebook.com/paleatorder" target="_blank" rel="noopener noreferrer">
              <Box component="img" src={FacebookIcon} alt="Facebook" width={24} height={24} />
            </a>
          </IconButton>

          {/* Instagram */}
          <IconButton color="inherit">
            <a href="https://www.instagram.com/paleat.ps" target="_blank" rel="noopener noreferrer">
              <Box component="img" src={InstagramIcon} alt="Instagram" width={24} height={24} />
            </a>
          </IconButton>

          {/* YouTube */}
          <IconButton color="inherit">
            <a href="https://www.youtube.com/channel/UCktOHWBXm5yHhKgPatWQ7GQ" target="_blank" rel="noopener noreferrer">
              <Box component="img" src={YouTubeIcon} alt="YouTube" width={24} height={24} />
            </a>
          </IconButton>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
