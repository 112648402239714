// components/Contact.js
import { Alert, Box, Button, Snackbar, TextField, Typography } from '@mui/material';
import React, { useState } from 'react';

const Contact = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: ''
  });
  const [openSnackbar, setOpenSnackbar] = useState(false);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('Form submitted:', formData);
    setOpenSnackbar(true); // Show success toast
    setFormData({ name: '', email: '', message: '' }); // Reset fields
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackbar(false);
  };

  return (
    <Box
      sx={{
        padding: '20px',
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'rgba(255, 255, 255, 0.3)',
        backdropFilter: 'blur(10px)',
        WebkitBackdropFilter: 'blur(10px)',
        borderRadius: '8px',
        boxShadow: '0 8px 16px rgba(0, 0, 0, 0.2)',
        maxWidth: '600px',
        margin: 'auto'
      }}
    >
      <Typography variant="h4" gutterBottom style={{ fontWeight: 'bold', textAlign: 'center' }}>
        Contact Us
      </Typography>
      <Typography variant="body1" color="textSecondary" gutterBottom style={{ textAlign: 'center', marginBottom: '20px' }}>
        We’d love to hear from you! Fill out the form below, and we’ll get back to you shortly.
      </Typography>

      <form onSubmit={handleSubmit} style={{ width: '100%' }}>
        <TextField
          label="Your Name"
          name="name"
          variant="outlined"
          fullWidth
          required
          margin="normal"
          value={formData.name}
          onChange={handleChange}
          sx={{ backgroundColor: 'white', borderRadius: '4px' }}
        />

        <TextField
          label="Your Email"
          name="email"
          type="email"
          variant="outlined"
          fullWidth
          required
          margin="normal"
          value={formData.email}
          onChange={handleChange}
          sx={{ backgroundColor: 'white', borderRadius: '4px' }}
        />

        <TextField
          label="Your Message"
          name="message"
          variant="outlined"
          fullWidth
          required
          margin="normal"
          multiline
          rows={4}
          value={formData.message}
          onChange={handleChange}
          sx={{ backgroundColor: 'white', borderRadius: '4px' }}
        />

        <Button
          type="submit"
          variant="contained"
          color="primary"
          fullWidth
          sx={{
            marginTop: '20px',
            padding: '10px',
            fontWeight: 'bold',
            borderRadius: '8px',
            backgroundColor: '#1976d2',
            '&:hover': {
              backgroundColor: '#115293',
            }
          }}
        >
          Submit
        </Button>
      </form>

      {/* Snackbar for Success Toast */}
      <Snackbar
        open={openSnackbar}
        autoHideDuration={4000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert onClose={handleCloseSnackbar} severity="success" sx={{ width: '100%' }}>
          Message sent successfully!
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default Contact;
