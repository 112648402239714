import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom'; // Assuming you're using React Router

const RedirectToApp = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;

    // App Store and Play Store links
    const iosAppStoreLink = 'https://apps.apple.com/app/id1394044761'; // iOS App Store link
    const androidPlayStoreLink = 'https://play.google.com/store/apps/details?id=com.modym.rewards.paleat'; // Play Store link

    // Redirect logic based on the user’s device
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      // iOS - Redirect to App Store
      window.location.href = iosAppStoreLink;
    } else if (/android/i.test(userAgent)) {
      // Android - Redirect to Play Store
      window.location.href = androidPlayStoreLink;
    } else {
      // Non-mobile devices: Fallback to website
      window.location.href = 'https://pal-eat.com';
    }

    // Immediately navigate back to the home page ("/")
    navigate('/');
  }, [navigate]);

  return null; // No UI needed since we're redirecting immediately
};

export default RedirectToApp;
