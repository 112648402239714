import { Button, Container, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next'; // Import translation hook
import { useNavigate } from 'react-router-dom'; // Import navigation hook

const PageNotFound = () => {
  const { t } = useTranslation(); // Hook for translations
  const navigate = useNavigate(); // Hook for navigating

  const goToHome = () => {
    navigate('/'); // Navigate to the home page
  };

  return (
    <Container
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: '100vh', // Full page height
        textAlign: 'center',
      }}
    >
      {/* Message for "Page Not Found" */}
      <Typography variant="h4" gutterBottom>
        {t('pageNotFoundTitle')} {/* Translated title */}
      </Typography>

      <Typography variant="body1" paragraph>
        {t('pageNotFoundMessage')} {/* Translated message */}
      </Typography>

      {/* Button to go back to the home page */}
      <Button
        variant="contained"
        color="primary"
        onClick={goToHome}
        sx={{ marginTop: '20px' }}
      >
        {t('goToHome')} {/* Translated button text */}
      </Button>
    </Container>
  );
};

export default PageNotFound;
