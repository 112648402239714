import { Box, Button, Container, Grid, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next'; // Import the useTranslation hook
import GooglePlayImage from '../assets/res/appstore.svg';
import AppStoreImage from '../assets/res/googleplay.svg';
import PhoneImage from '../assets/res/phone_m.png';

const Hero = () => {
  const { t, i18n } = useTranslation(); // Access translation function
  const isArabic = i18n.language === 'ar'; // Check if the current language is Arabic (RTL)

  return (
    <Box
      sx={{
        flexGrow: 1,
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <Container maxWidth="lg">
        <Grid container spacing={4} alignItems="center" justifyContent="center">
          {/* Left Column: Text and Buttons */}
          <Grid
            item
            xs={12}
            md={6}
            sx={{
              textAlign: { xs: 'center', md: isArabic ? 'right' : 'left' }, // Adjust text alignment based on language
            }}
          >
            {/* Bold and large text for "Think, Click and Pick" with top to bottom animation */}
            <Typography
              variant="h2"
              gutterBottom
              sx={{
                fontWeight: 'bold',
                fontSize: { xs: '2.5rem', md: '3.5rem' },
                animation: 'moveTopToBottom 0.5s ease-in-out forwards',
              }}
            >
              {t('title')} {/* Use translation function */}
            </Typography>

            {/* Description with top to bottom animation */}
            <Typography
              variant="body1"
              color="textSecondary"
              paragraph
              sx={{
                animation: 'moveTopToBottomDescription 0.5s ease-in-out forwards',
              }}
            >
              {t('description')} {/* Use translation function */}
            </Typography>

            {/* Google Play and App Store Buttons */}
            <Box
              mt={2}
              display="flex"
              justifyContent={{ xs: 'center', md: isArabic ? 'flex-end' : 'flex-start' }} // Adjust button alignment
            >
              {/* Google Play button with dynamic margin for RTL */}
              <Button
                sx={{
                  marginLeft: isArabic ? '10px' : 0, // In RTL, margin is on the left
                  marginRight: isArabic ? 0 : '10px', // In LTR, margin is on the right
                  padding: 0,
                  animation: 'moveLeftToRight 0.5s ease-in-out forwards',
                }}
                component="a"
                href="https://play.google.com/store/apps/details?id=com.modym.rewards.paleat"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={GooglePlayImage} alt="Google Play" width="180" />
              </Button>

              {/* App Store button with dynamic margin for RTL */}
              <Button
                sx={{
                  padding: 0,
                  animation: 'moveRightToLeft 0.5s ease-in-out forwards',
                }}
                component="a"
                href="https://apps.apple.com/kh/app/paleat/id1394044761"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={AppStoreImage} alt="App Store" width="180" />
              </Button>
            </Box>
          </Grid>

          {/* Right Column: Phone Image with floating animation */}
          <Grid item xs={12} md={6} display="flex" justifyContent="center">
            <Box
              component="img"
              src={PhoneImage}
              alt="Phone"
              sx={{
                maxWidth: '430px', // Maximum width for larger screens
                height: 'auto', // Maintain aspect ratio
                animation: 'float 3s ease-in-out infinite',
              }}
            />
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default Hero;
