import createCache from '@emotion/cache';
import { CacheProvider } from '@emotion/react';
import { Box, createTheme, CssBaseline, ThemeProvider } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next'; // Import the useTranslation hook
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom'; // Import Router components
import { prefixer } from 'stylis';
import BackgroundSVG from './assets/res/Background.png'; // Optional background image
import Footer from './components/Footer';
import Header from './components/Header';
import Hero from './components/Hero';
import PrivacyPolicy from './components/PrivacyPolicy';
import TermsConditions from './components/TermsConditions';
import './styles/App.css'; // Import the CSS for fade-in

// Import Markdown files and logos
import PrivacyPolicyPalEatAR from './assets/files/PalEat/paleat-privacy-ar.md';
import PrivacyPolicyPalEat from './assets/files/PalEat/paleat-privacy.md';
import TermsConditionsPalEatAR from './assets/files/PalEat/paleat-terms-ar.md';
import TermsConditionsPalEat from './assets/files/PalEat/paleat-terms.md';
import PaleatLogo from './assets/res/Logo_Light.svg'; // Path to your app logo

import PrivacyPolicyRiderAr from './assets/files/Rider/rider-privacy-ar.md';
import PrivacyPolicyRider from './assets/files/Rider/rider-privacy.md';
import TermsConditionsRideARr from './assets/files/Rider/rider-terms-ar.md';
import TermsConditionsRider from './assets/files/Rider/rider-terms.md';
import RiderLogo from './assets/res/Rider-Logo.svg'; // Path to your app logo


import TermsConditionsArabBankAR from './assets/files/ArabBank/arabbank-terms-ar.md';
import TermsConditionsArabBank from './assets/files/ArabBank/arabbank-terms.md';
import ArabLogo from './assets/res/arab-bank.png'; // Path to your app logo
import Contact from './components/Contact';
import PageNotFound from './components/PageNotFound';
import RedirectToApp from './components/RedirectToApp';
import RedirectToWhatsApp from './components/RedirectToWhatsApp';

// Create a custom theme with direction support
const theme = (isArabic) => createTheme({
  direction: isArabic ? 'rtl' : 'ltr',  // Set the global direction for the theme
  typography: {
    fontFamily: 'Inter, Arial, sans-serif',  // Set Inter as the default font
  },
  palette: {
    background: {
      default: '#F2F7FF',  // Define your global background color
    },
  },
});

// Create RTL and LTR caches for emotion
const createEmotionCache = (isRtl) => createCache({
  key: isRtl ? 'muirtl' : 'mui',
  stylisPlugins: isRtl ? [prefixer] : [],
});

const App = () => {
  const { i18n } = useTranslation();  // Get the i18n instance
  const currentLanguage = i18n.language;  // Detect the current language
  const isArabic = currentLanguage === 'ar';  // Check if the current language is Arabic

  // Create emotion cache based on the current language direction
  const cache = createEmotionCache(isArabic);

  // Set the document's direction attribute globally
  document.documentElement.dir = isArabic ? 'rtl' : 'ltr';

  return (
    <CacheProvider value={cache}>
      <ThemeProvider theme={theme(isArabic)}>
        <CssBaseline />
        <Router>
          <Box
            className="fade-in"
            sx={{
              position: 'relative',
              minHeight: '100vh',
              backgroundImage: `url(${BackgroundSVG})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat',
            }}
          >
            <Header />
            <Routes>
              <Route path="/" element={<Hero />} />  {/* Main route */}
              
              {/* Add the /redirect route */}
              <Route path="/redirect" element={<RedirectToApp />} />

                {/* Contact Route */}
  <Route path="/contact" element={<Contact />} />  {/* New Contact route */}

  <Route 
          path="/rider-form" 
          element={
            <RedirectToWhatsApp 
              phoneNumber="972568844406" 
              message="Hello, I'm interested in becoming a rider."
            />
          } 
        />

              {/* Paleat Policy Pages */}
              <Route
                path="/paleat-privacy-policy"
                element={
                  <PrivacyPolicy
                    file={isArabic ? PrivacyPolicyPalEatAR : PrivacyPolicyPalEat}
                    logo={PaleatLogo}
                  />
                }
              />
              <Route
                path="/paleat-terms-conditions"
                element={
                  <TermsConditions
                    file={isArabic ? TermsConditionsPalEatAR : TermsConditionsPalEat}
                    logo={PaleatLogo}
                  />
                }
              />

              {/* Rider Policy Pages */}
              <Route
                path="/paleat-rider-privacy-policy"
                element={
                  <PrivacyPolicy
                    file={isArabic ? PrivacyPolicyRiderAr : PrivacyPolicyRider}
                    logo={RiderLogo}
                  />
                }
              />
              <Route
                path="/paleat-rider-terms-conditions"
                element={
                  <TermsConditions
                    file={isArabic ? TermsConditionsRideARr : TermsConditionsRider}
                    logo={RiderLogo}
                  />
                }
              />
              
              <Route
                path="/arabbank-terms-conditions"
                element={
                  <TermsConditions
                    file={isArabic ? TermsConditionsArabBankAR : TermsConditionsArabBank}
                    logo={ArabLogo}
                  />
                }
              />

              {/* Add a wildcard route for 404 */}
              <Route path="*" element={<PageNotFound />} />
            </Routes>
            <Footer />
          </Box>
        </Router>
      </ThemeProvider>
    </CacheProvider>
  );

};

export default App;
