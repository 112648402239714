import { Box, Container, Grid, Link, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next'; // Import translation hook
import Logo from '../assets/res/portous.svg'; // Replace with your logo path

const Footer = () => {
  const { t, i18n } = useTranslation(); // Access the translation and i18n instance
  const isArabic = i18n.language === 'ar'; // Check if the current language is Arabic (RTL)

  // Function to change the language
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  return (
    <Box
      sx={{
        position: 'absolute', // Make the footer sit at the bottom of the container
        bottom: 0, // Align to the bottom of the page
        left: 0,
        right: 0,
        backgroundColor: 'rgba(255, 255, 255, 0.5)',  // Black background
        color: '#fff',
        margin:'20px',
        padding: '20px 0 20px 0',  // Add bottom padding
        backdropFilter: 'blur(10px)',  // Blur effect
        WebkitBackdropFilter: 'blur(10px)',  // For Safari support
        borderTop: '0.5px solid rgba(0, 0, 0, 0.1)',  // Optional border to enhance glass effect
  
        borderRadius:'50px'
      
      }}
    >
      <Container maxWidth="xl">
        <Grid container alignItems="center" justifyContent="space-between">
          {/* Left side: Logo and "All rights reserved" */}
          <Grid item>
            <Box display="flex" alignItems="center">
              <Link href="https://www.portous.com" target="_blank" rel="noopener noreferrer"> {/* Clickable link for the logo */}
                <Box
                  component="img"
                  src={Logo}
                  alt="Logo"
                  sx={{
                    width: '40px',
                    marginRight: isArabic ? '0' : '10px', // Adjust margins based on language
                    marginLeft: isArabic ? '10px' : '0',  // Adjust for RTL
                    cursor: 'pointer',  // Pointer cursor to indicate it's clickable
                  }}
                />
              </Link>
              <Typography variant="body2">
                <Link
                  href="https://www.portous.com" 
                  target="_blank" 
                  rel="noopener noreferrer" 
                  sx={{ color: '#000', textDecoration: 'none', cursor: 'pointer' }} 
                >
                  © 2024 Portous. {t('allRightsReserved')} {/* Translated text */}
                </Link>
              </Typography>
            </Box>
          </Grid>

          {/* Right side: Language switcher */}
          <Grid item>
            <Typography sx={{                  color: '#000'}}variant="body2">
              {/* Language switcher: Clickable links */}
              <Link
                href="#"
                onClick={() => changeLanguage('en')}
                sx={{
                  color: '#000',
                  textDecoration: 'none',
                  marginRight: isArabic ? '0' : '10px',  // Adjust margins for LTR
                  marginLeft: isArabic ? '10px' : '0',   // Adjust for RTL
                  cursor: 'pointer',
                }}
              >
                English
              </Link>
              |
              <Link
                href="#"
                onClick={() => changeLanguage('ar')}
                sx={{
                  color: '#000',
                  textDecoration: 'none',
                  marginLeft: isArabic ? '0' : '10px',  // Adjust for RTL
                  marginRight: isArabic ? '10px' : '0',  // Adjust for LTR
                  cursor: 'pointer',
                }}
              >
                عربي
              </Link>
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default Footer;
