// components/RedirectToWhatsApp.js
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { Box, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';

const RedirectToWhatsApp = ({ phoneNumber, message }) => {
  useEffect(() => {
    const encodedMessage = encodeURIComponent(message || "Hello, I would like to know more.");
    const whatsappURL = `https://wa.me/${phoneNumber}?text=${encodedMessage}`;

    // Redirect to WhatsApp after a short delay
    const timer = setTimeout(() => {
      window.location.href = whatsappURL;
    }, 2000); // 2-second delay for the animation effect

    return () => clearTimeout(timer); // Clean up timer on component unmount
  }, [phoneNumber, message]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
        textAlign: 'center'
      }}
    >
      {/* Animated WhatsApp Icon */}
      <WhatsAppIcon
        sx={{
          fontSize: 80,
          color: '#25D366',
          animation: 'pulse 1s infinite'
        }}
      />

      <Typography variant="h6" sx={{ marginTop: '16px', color: 'text.secondary' }}>
        Redirecting to WhatsApp...
      </Typography>

      {/* CSS for the pulse animation */}
      <style>
        {`
          @keyframes pulse {
            0% { transform: scale(1); }
            50% { transform: scale(1.1); }
            100% { transform: scale(1); }
          }
        `}
      </style>
    </Box>
  );
};

RedirectToWhatsApp.propTypes = {
  phoneNumber: PropTypes.string.isRequired,
  message: PropTypes.string,
};

export default RedirectToWhatsApp;
